import {FreeSpace} from "../types/Freespace";
import { convertFromRaw, EditorState } from "draft-js";

/**
   * マークアップの内容が存在するかチェック
   * @param content 
   * @returns 
   */
export const markupContentExist = (content: string) => {
    if(content) {
        const htmlContent = EditorState.createWithContent(convertFromRaw(JSON.parse(content)));
        return (htmlContent.getCurrentContent().hasText() && htmlContent.getCurrentContent().getPlainText().length > 0);
    }
    return false;
}

/**
 * OTS設定フリースペースPC用コンテンツチェック
 * @param freeSpace
 */
export const isAdminFreeSpacePC = (freeSpace: FreeSpace | undefined) => {
    if (freeSpace === undefined) {
        return false;
    }

    if (freeSpace.adminFreeSpace?.content?.pc && markupContentExist(freeSpace.adminFreeSpace.content.pc)) {
        return true;
    }
    return false;
}

/**
 * OTS設定フリースペースSP用コンテンツチェック
 * @param freeSpace
 */
export const isAdminFreeSpaceSP = (freeSpace: FreeSpace | undefined) => {
    if (freeSpace === undefined) {
        return false;
    }

    if (freeSpace.adminFreeSpace?.content?.sp && markupContentExist(freeSpace.adminFreeSpace.content.sp)) {
        return true;
    }
    return false;
}