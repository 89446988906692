import React from "react";

// toggle button
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

// accordion・media-query
import {
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";
import {useTranslation} from "react-i18next";

// narrow-down-flame
const SearchBoxStay: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <div className="card-narrow-down">
    <div className="animation-fade active">
      <div className="serchbox-stay">{children}</div>
    </div>
  </div>
);

// props(部屋タイプ・食事の種類の共通部分用)
type FilterGroupProps = {
  filterItems: { value: string; label: string }[];
  filterState: string[];
  handleFilter: (
    event: React.MouseEvent<HTMLElement>,
    newFilter: string[]
  ) => void;
  title: string;
  firstItem?: boolean;
};

// Props(デフォルトコンポーネント用)
type Props = {
  roomTypeFilter: { label: string; value: string }[];
  mealFilter: { label: string; value: string }[];
  roomTypeFilterState: string[];
  mealFilterState: string[];
  handleRoomTypeFilter: (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string[]
  ) => void;
  handleMealFilter: (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string[]
  ) => void;
};

// コンポーネント(部屋タイプ・食事の種類の共通部分用)
const FilterGroup: React.FC<FilterGroupProps> = ({
  filterItems,
  filterState,
  handleFilter,
  title,
  firstItem = false,
}) => (
  <div>
    <dt
      className={`c-navy fz-13 fz-14-md fw-b fx-sh pb-8 ${
        firstItem ? "" : "pt-16"
      }`}
    >
      {title}
    </dt>
    <dd className="fx-1">
      <div
        role="group"
        className="MuiToggleButtonGroup-root w-100per d-b fw-w"
        aria-label="all"
      >
        <ToggleButtonGroup
          value={filterState}
          onChange={handleFilter}
          aria-label="text formatting"
          className="box-toggle_button box-narrow w-a"
        >
          {filterItems.map((row, index) => (
            <ToggleButton
              key={title + "_" + index}
              value={row.value}
              aria-label={row.value}
              className="box-toggle_button-item"
            >
              {row.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    </dd>
  </div>
);

// デフォルトコンポーネント
const PartsHotelPlanNarrowDown: React.FC<Props> = ({
  roomTypeFilter,
  mealFilter,
  roomTypeFilterState,
  mealFilterState,
  handleRoomTypeFilter,
  handleMealFilter,
}) => {
  const { t,i18n } = useTranslation();
  const matches = useMediaQuery("(min-width:992px)");

  return matches ? (
    <SearchBoxStay>
      <p className="serchbox-stay-item fz-16 mb-16 mb-0-md fw-b ta-c pr-20-md w-250-md">
        {t("StaySearchbox.条件で絞り込む")}
      </p>
      <dl className="serchbox-stay-item d-f-md fw-w fd-c bl-gray-md pl-40-md">
        {roomTypeFilter && roomTypeFilter.length > 0 && (
          <FilterGroup
            filterItems={roomTypeFilter}
            filterState={roomTypeFilterState}
            handleFilter={handleRoomTypeFilter}
            title={t("SearchCounter.お部屋")}
            firstItem
          />
        )}

        {mealFilter && mealFilter.length > 0 && (
          <FilterGroup
            filterItems={mealFilter}
            filterState={mealFilterState}
            handleFilter={handleMealFilter}
            title={t("StaySearchbox.お食事")}
          />
        )}
      </dl>
    </SearchBoxStay>
  ) : (
    <SearchBoxStay>
      <Accordion>
        <AccordionSummary>
          <div className="d-f jc-c w-100per">
            <p className="serchbox-stay-item fz-16 fw-b ta-c pr-20-md w-250-md">
              {t("StaySearchbox.条件で絞り込む")}
            </p>
            <span className="icon-accodion-toggle bg-glay ml-8"></span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <dl className="serchbox-stay-item d-f-md fw-w w-100per bdr-t-n-md bdr-t-gray bl-gray-md pl-40-md">
            {roomTypeFilter && roomTypeFilter.length > 0 && (
              <FilterGroup
                filterItems={roomTypeFilter}
                filterState={roomTypeFilterState}
                handleFilter={handleRoomTypeFilter}
                title={t("SearchCounter.お部屋")}
              />
            )}

            {mealFilter && mealFilter.length > 0 && (
              <FilterGroup
                filterItems={mealFilter}
                filterState={mealFilterState}
                handleFilter={handleMealFilter}
                title={t("StaySearchbox.お食事")}
              />
            )}
          </dl>
        </AccordionDetails>
      </Accordion>
    </SearchBoxStay>
  );
};

export default PartsHotelPlanNarrowDown;
