import React, { Fragment } from "react";

// // for slider
// import Carousel from "react-material-ui-carousel";

//swiper
import { Navigation, Pagination, A11y, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.css';
import 'swiper/modules/effect-fade/effect-fade.min.css';
import 'swiper/modules/pagination/pagination.min.css';

// for styles
import {
  // Button,
  Backdrop
} from "@material-ui/core/";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

import Modal from "@material-ui/core/Modal";
import {roomData} from "../../types/Hotel";
import DOMPurify from "dompurify";
import { getRoomTypeLabel } from "../../utils/hotel";
import { useTranslation } from "react-i18next";
import { PartsModalHeader } from "../parts";
import Button from "@material-ui/core/Button";

// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  roomData: roomData|undefined,
  callback: () => void,
}

const createMarkup = (data: string | undefined) => {
  if(data) {
    const content = data.replace(/\n/g, '<br />');
    return  {
      __html: DOMPurify.sanitize(content)
    }
  }
}

const RoomDetailModal: React.FC<Props> = ({
  isShow,
  roomData,
  callback,
}) => {

  const { t, i18n } = useTranslation();

  return (
    <>
    <Modal
      open={isShow}
      onClose={callback}
      className="modal"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <div className="modal-fixed">
        
        <div className="modal-fixed">
          <PartsModalHeader
            headingText={"客室詳細"}
            close={callback}
          />
          <div className="modal-fixed-content">
            <div className="modal-fixed-content-item">
              {roomData && roomData?.image_list && (<>
                <div className="modal-fixed-content-item-carousel">
                  <Swiper
                    // install Swiper modules
                    modules={[Navigation, Pagination, A11y, EffectFade]}
                    loop={true}
                    effect="fade"
                    pagination={{clickable: true}}
                  >
                    {roomData?.image_list.map((image, i) => (
                      <SwiperSlide key={i}><img src={image.image_url}/></SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </>)}
              <div className="modal-fixed-content-item-overview">
                <div className="fz-18 fz-20-md fw-b c-navy mb-16 mb-24-md">
                  {roomData?.room_name}
                </div>
                <div className="box-light-gray small">
                  <p
                    className="lh-1_75 fz-14"
                    dangerouslySetInnerHTML={createMarkup(roomData?.description)}
                  ></p>
                </div>
              </div>
            </div>

            <div className="modal-fixed-conten-detail mt-8 mt-0-md">
              {roomData?.room_type_code && (
                <p className="fz-13 fz-14-md fw-b c-navy">
                  【{t("Hotel.ルームタイプ")}】{getRoomTypeLabel(roomData?.room_type_code, i18n.language)}
                </p>
              )}

              <div className="list-stay-label mt-8">
                <ul className="list-stay-label-list">
                  {roomData?.room_feature_list?.map(
                    (room_feature, i) => (
                      <li key={i} className="list-stay-label-list-item">
                        {room_feature.name}
                      </li>
                    )
                  )}
                </ul>
              </div>
              {roomData?.bedding_list && (
                <p className="fz-13 fz-14-md mt-16">
                  最大対応寝具 :
                  {roomData?.bedding_list?.map((bedding, i) => (
                    <Fragment key={i}>{` ${bedding.name}`}</Fragment>
                  ))}
                </p>
              )}
              <ul className="list-alternating mt-24 mt-32-md">
                {roomData?.room_amenity_list?.map(
                  (amenity) => (
                    amenity.name !== null && (
                      <li key={amenity.name}>{amenity.name}</li>
                    )
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    </>
  );
};

export default RoomDetailModal;
