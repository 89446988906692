import React from "react";

// // for google map
// import GoogleMapTemplate from "../google/Maps";

// // for slider
// import Carousel from "react-material-ui-carousel";

//swiper
import { Navigation, Pagination, A11y, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";
import "swiper/modules/effect-fade/effect-fade.min.css";
import "swiper/modules/pagination/pagination.min.css";

// for styles
import {
  Button,
  Backdrop
} from "@material-ui/core/";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

import Modal from "@material-ui/core/Modal";
import {
  // hotelList, 
  hotelPlanDetail,
  roomData, roomDataPlanType
} from "../../types/Hotel";
import DOMPurify from "dompurify";
import {PlanSelectFormData} from "../../types/planSelectForm";
import {diffHotelPrice, isHotelStaySubPrice} from "../../utils/hotel";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../../constants/languages";

// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean;
  isUpdate: boolean,
  updateIndex: number | undefined,
  planData: roomDataPlanType | undefined,
  roomData: roomData | undefined,
  planSelectFormData: PlanSelectFormData|undefined
  updateSelectHotel(planData: roomDataPlanType, roomData:roomData): void
  callback: () => void;
};

const createMarkup = (data: string | undefined) => {
  if(data) {
    const content = data.replace(/\n/g, '<br />');
    return  {
      __html: DOMPurify.sanitize(content)
    }
  }
}

const PlanDetailModal: React.FC<Props> = ({ 
  isShow, 
  isUpdate,
  updateIndex,
  planData, 
  roomData,
  planSelectFormData,
  updateSelectHotel,
  callback 
}) => {
  
  const { t, i18n } = useTranslation();

  const checkIn = planSelectFormData?.searchParams.checkin ?? '';
  const checkOut = planSelectFormData?.searchParams.checkout ?? '';

  return (
    <>
      <Modal
        open={isShow}
        onClose={callback}
        className="modal"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
      >
        <div className="modal-fixed">
          <div className="modal-fixed-heading">
            <p className="modal-fixed-heading-text">{t("PlanDetailModal.プラン詳細")}</p>
            <p className="modal-fixed-close">
              <Button onClick={callback} className="modal-fixed-close-button">
                <i className="modal-fixed-close-button-icon"></i>{t("Common.閉じる")}
              </Button>
            </p>
          </div>
          {roomData !== undefined && planData !== undefined && (
            <>
              <div className="modal-fixed-content">
                <div className="modal-fixed-content-item">
                  {(roomData.image_list || planData.plan_image_list) && (
                    <>
                      <div className="modal-fixed-content-item-carousel">
                        <Swiper
                          modules={[Navigation, Pagination, A11y, EffectFade]}
                          loop={true}
                          effect="fade"
                          pagination={{clickable: true}}
                        >
                          {planData.plan_image_list !== undefined ? (
                            planData.plan_image_list.map((plan_image, i) => (
                              <SwiperSlide key={i}><img src={plan_image.plan_image_url} alt={planData.plan_name}/></SwiperSlide>
                            ))
                          ) : (
                            roomData.image_list.map((room_image, i) => (
                              <SwiperSlide key={i}><img src={room_image.image_url} alt={room_image.image_description}/></SwiperSlide>
                            ))
                          )}
                        </Swiper>
                      </div>
                    </>
                  )}
                  <div className="modal-fixed-content-item-heading">
                    {planData.plan_name}
                  </div>
                </div>

                <div className="modal-fixed-conten-detail">
                  <div className="box-light-gray">
                    <p dangerouslySetInnerHTML={createMarkup(planData.plan_description)}></p>
                  </div>
                </div>
              </div>

              <div className="modal-fixed-bottom variable">
                <div className="modal-fixed-bottom-content">
                  {planData?.stay_detail_list && isHotelStaySubPrice(planData.stay_detail_list) ? (
                    <>
                      <div className="modal-fixed-bottom-content-text">
                        <p>
                          <span className="fz-13 fz-14-md fw-b">{t("HotelPlanSearchResult.追加料金")}</span>
                          <br/>
                          <span className="fz-10 fz-12-md">
                             {/* （１人あたり） */}
                            （{t("HotelPlanSearchResult.１人あたり")}）
                          </span>
                        </p>
                        <p className="c-red fw-b ml-10-md">
                        <span className="fz-20 fz-24-md">
                          {diffHotelPrice(planSelectFormData, {
                            isUpdate: isUpdate,
                            step: "plan",
                            updateHotelIndex: updateIndex,
                            updatePlan: planData,
                            updateHotel: undefined,
                            checkIn: planSelectFormData?.searchParams?.checkin ?? "",
                            checkOut: planSelectFormData?.searchParams?.checkout ?? "",
                          })}
                        </span>
                          <span className="fz-12">{t("Common.円")}</span>
                        </p>
                      </div>
                      <div className="modal-fixed-bottom-content-link">
                        <button
                          onClick={() => updateSelectHotel(planData, roomData)}
                          className="button-medium-orange padding-large button-variable w-100per"
                        >
                          {
                            i18n.language === LANGUAGES.JA ? (
                              <>このプランを<br className="d-n-md"/>選択する</>
                            ) : (
                              <>選擇此住宿方案</>
                            )
                          }
                        </button>
                      </div>
                    </>
                  ) : (
                    <span className="button-medium-disabled w-100per">{t("SectionPlan.受付終了")}</span>
                  )}
                </div>
              </div>
            </>
          )}
        </div>

      </Modal>
    </>
  );
};

export default PlanDetailModal;
