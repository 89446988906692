import React, {useState} from "react";

import StaySearchbox from "./../../search/StaySearchbox";

//scss
import "../../../scss/common.scss";
import "../../../scss/component.scss";

import {Hotel, HotelAllSearchResponse, selectHotelDetail} from "../../../types/Hotel"
import {
  PlanSelectFormData, 
} from "../../../types/planSelectForm";
import { useTranslation } from "react-i18next";

type Props = {
  canSelect: boolean;
  isLoading: boolean;
  isListLoading: boolean;
  planSelectFormData: PlanSelectFormData;
  selectPlan(planSelectFormData: PlanSelectFormData): void;
  callback: () => void;
  sort: (type: string, val: number) => void;
  hotelAllData: HotelAllSearchResponse | undefined;
  hotelController: AbortController;
  handleHotelSelected: (selectedHotel: selectHotelDetail) => void;
}

const SectionStay: React.FC<Props> = ({
  canSelect,
  isLoading,
  isListLoading,
  planSelectFormData,
  selectPlan,
  callback,
  sort,
  hotelAllData,
  hotelController,
  handleHotelSelected
}) => {
  
  const { t } = useTranslation();

  const selectHotelPlan = (selectData: PlanSelectFormData ) => {
    selectPlan(selectData);
  }

  const [hotelSelectedStep, setHotelSelectedStep] = useState<number>(1); // 1=宿泊施設、2=宿泊プラン

  return (
    <>
      <section id="section-hotel">
        <div className="wrap-content-900">
          <h2 className="heading-2 mb-24-md">{t("SectionStay.宿泊の選択")}</h2>

          {canSelect === false ? (
            <div className="box-white large">
              <p className="c-light_gray fw-b">
                {t("SectionStay.ご希望の宿泊施設をお選びください")}
              </p>
            </div>
          ) : (
            <>
              {hotelAllData?.success && (
                <p className="fz-14 fz-16-md fw-b mb-16 mb-20-md">
                  {hotelSelectedStep === 1
                    ? t("SectionStay.ご希望の宿泊施設をお選びください")
                    : t("SectionStay.ご希望の宿泊プランをお選びください")}
                </p>
              )}
              <StaySearchbox
                planSelectFormData={planSelectFormData}
                isLoading={isLoading}
                isListLoading={isListLoading}
                selectPlanSelectFormData={selectHotelPlan}
                callback={callback}
                setHotelSelectedStep={setHotelSelectedStep}
                sort={sort}
                hotelAllData={hotelAllData}
                hotelController={hotelController}
                handleHotelSelected={handleHotelSelected}
              ></StaySearchbox>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default SectionStay;
