import React, {Fragment, useEffect} from "react";

//scss
import "../../../scss/common.scss";
import "../../../scss/component.scss";
import {PlanSelectFormData} from "../../../types/planSelectForm";
import {dateFormat, dateFormatWithLocale, toDisplayTime} from "../../../utils/convert";
import {getCompleteScheduleData} from "../../../utils/schedule";
import {planSelectScheduleCompleteList} from "../../../types/planSelectConfirm";
// import {carRentalSaveData} from "../../../types/carRental";
// import {getCarRentalOfficeLabel} from "../../../utils/carRental";
// import {
//   getFlightClassLabel,
//   getFlightCodeLabel,
//   getFlightNumberForDisplay,
//   getFlightPeopleConfirmLabel,
//   getFlightTime
// } from "../../../utils/flight";
import {showMealLabel, showRoomText} from "../../../utils/hotel";
import {devLog} from "../../../utils/errors";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n/config";
import {getCarRentalAnshinOptionItem, isCarRentalOptions} from "../../../utils/carRental";



// 基本プラン・追加オプションの行を表示するコンポーネント
type PropsForTableRowOfPlan = {
  heading: string; 
  startDate: string; 
  startTime: string; 
  planName: string; 
  addClass: string;
};

const TableRowOfPlan: React.FC<PropsForTableRowOfPlan> = (props) => {
  return (
    <>
      <td className={`table-responsive-group-heading table-bgc-light_gray ta-c fw-b ${props.addClass}`}>
        {props.heading}
      </td>
      <td>
        <p className="mb-5">{`${props.startDate} ${props.startTime}`}</p>
        <p>{props.planName}</p>
      </td>
    </>
  )
}


// レンタカーの行を表示するコンポーネント
type PropsForTableRowOfCarRental = {
  headingMode: string; 
  targetDate: string; 
  targetTime: string; 
  office: string; 
  details: any[];
  count_option: any;
  addClass: string;
};

const TableRowOfCarRental: React.FC<PropsForTableRowOfCarRental> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <td className={`table-responsive-group-heading table-bgc-light_gray ta-c fw-b ${props.addClass}`}>
        {t("CarRental.レンタカー")}
        <br /> ({props.headingMode})
      </td>
      <td>
        <div className="mb-10">
          <p>{`${props.targetDate} ${props.targetTime}`}</p>
          <p>{props.office}</p>
        </div>
        <div>
          {props.details.map((carRentalDetail: any, cIndex: any) => (
            <>
              <p>
                ■{t('SectionPlan.プラン')}：
                <Fragment key={cIndex}>
                  <span className="mr-20">{carRentalDetail.planDetail.plan_name}</span>
                </Fragment>
              </p>
              <p>
                ■{t('CarRental.車種')}：
                <Fragment key={cIndex}>
                  <span className="mr-20">{carRentalDetail.planDetail.class_model}</span>
                </Fragment>
              </p>

              {isCarRentalOptions(carRentalDetail.count_option) && (
                <p>
                  ■{t('CarRental.オプション')}：
                  {carRentalDetail.count_option.anshin.used && <span className="mr-20">{t('CarRental.' + getCarRentalAnshinOptionItem(carRentalDetail.count_option.anshin.option_name, 'label', i18n.language))}</span>}
                  {carRentalDetail.count_option.anshin_premium.used && <span className="mr-20">{t('CarRental.' + getCarRentalAnshinOptionItem(carRentalDetail.count_option.anshin_premium.option_name, 'label', i18n.language))}</span>}
                  {carRentalDetail.count_option.baby_seat.num > 0 && <span className="mr-20">{t('CarRental.ベビーシート')}×{carRentalDetail.count_option.baby_seat.num}</span>}
                  {carRentalDetail.count_option.child_seat.num > 0 && <span className="mr-20">{t('CarRental.チャイルドシート')}×{carRentalDetail.count_option.child_seat.num}</span>}
                  {carRentalDetail.count_option.junior_seat.num > 0 && <span className="mr-20">{t('CarRental.ジュニアシート')}×{carRentalDetail.count_option.junior_seat.num}</span>}
                </p>
              )}
            </>
          ))}
        </div>
      </td>
    </>    
  )
}


// ホテルの行を表示するコンポーネント
type PropsForTableRowOfHotel = {
  hotelName: string; 
  roomName: string; 
  roomText: string; 
  planName: string;
  mealType: string;
  addClass: string;
};

const TableRowOfHotel: React.FC<PropsForTableRowOfHotel> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <td className={`table-responsive-group-heading table-bgc-light_gray ta-c fw-b ${props.addClass}`}>
        {t("SectionSchedule.宿泊")}
      </td>
      <td>
        <p>{props.hotelName}</p>
        <p>{t("Hotel.部屋タイプ")}：{props.roomName}</p>
        <p>{t("Hotel.部屋数")}：{props.roomText}</p>
        <p>{t("SectionSchedule.プラン")}：{props.planName}</p>
        <p>{t("Hotel.食事条件")}：{props.mealType}</p>
      </td>
    </>
  )
}


type Props = {
  planSelectFormData: PlanSelectFormData,
  h2Class?: string,
  cardClass?: string,
}

// todo スケジュールテンプレートとわかる変数名に変更する（複数箇所で使用しているため）
const SectionDetail: React.FC<Props> = ({
  planSelectFormData,
  h2Class,
  cardClass,
}) => {
  
  const { t } = useTranslation();

  const searchPrams = planSelectFormData.searchParams;
  const [schedule, setSchedule] = React.useState<planSelectScheduleCompleteList[]>();
  useEffect(() => {
    const scheduleData = getCompleteScheduleData(planSelectFormData);
    devLog("scheduleData", scheduleData);
    setSchedule(scheduleData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const getH2Class = () => {
    if (h2Class) {
      return h2Class;
    }
    
    return "heading-2 mb-16 mt-32-md mb-20-md";
  }
  
  const getCardClass = () => {
    if (cardClass) {
      return cardClass;
    }
    
    return "card large";
  }

  // // 末尾の「便」を抜いた表示用フライト番号を返す
  // const getFlightNumberForDisplayExcludeSuffix = (flightData: { airline: string, entity: { air_code: string, air_sub_no: string }[] }) => {
  //   const flightNumber = getFlightNumberForDisplay(flightData.airline, flightData.entity[0].air_code, flightData.entity[0].air_sub_no);

  //   return flightNumber.replace("便", "");
  // }

  // スケジュールが空かどうかを判定する
  const emptySchedule = (scheduleData: planSelectScheduleCompleteList) => {
    // 0件、もしくはフライトの1件しかデータがない場合はスケジュールが空とみなす
    // todo: フライトのAPIを呼ばなくなったら0件の判定だけで良いはず
    if ((scheduleData.rows.length === 0 && scheduleData.first.data == null) || (scheduleData.rows.length === 1 && scheduleData.first.type === "flight")) {
      return true;
    }

    return false;
  }
  
  // スケジュールが空で、かつ最終日なら行ごと非表示にする
  const hideScheduleRow = (scheduleData: planSelectScheduleCompleteList) => {
    return emptySchedule(scheduleData) && scheduleData.isLast;
  }

  return (
    <>
    <section>
        {/* /行程表 */}
        <h2 className={getH2Class()}>行程表</h2>
        <div className={getCardClass()}>
          <table className="table-responsive table-small-top_bottom">
            <tbody>
              <tr className="table-plan-subtitle">
                <th
                  colSpan={2}
                  className="table-bgc-light_navy fz-16-md ta-c-md fw-b"
                >
                  {t("SectionDetail.旅行期間")}
                  {/* 旅遊日期 */}
                </th>
                <td>
                  {/* <p>出發日期：{dateFormat(searchPrams.start, 'YYYY年MM月DD日(ddd)')} 〜</p>
                  <p>回程日期：{dateFormat(searchPrams.end, 'YYYY年MM月DD日(ddd)')}</p> */}
                  <p>{t("SectionDetail.出発日")}：{dateFormatWithLocale(searchPrams.start, "YYYY年MM月DD日(ddd)", i18n.language)} 〜</p>
                  <p>{t("SectionDetail.帰着日")}：{dateFormatWithLocale(searchPrams.end, "YYYY年MM月DD日(ddd)", i18n.language)}</p>
                  <p>{planSelectFormData.info.days}{t("PlanSelectConfirm.日間")}</p>
                  {/* <p>{planSelectFormData.info.days}天</p> */}
                </td>
              </tr>

              {schedule? <>
                {/* scheduleDataは旅行日ごとで入ってくる */}
                {schedule.map((scheduleData, indexOfScheduleData) => (
                  <Fragment key={indexOfScheduleData}>
                    {/* scheduleData.first.dataはその旅行日の最初のアクションが入ってくる */}
                    {/* 最終日のスケジュールが0件、もしくはフライトの1件しかない場合は行ごと非表示 */}
                    <>
                      <tr className={hideScheduleRow(scheduleData) ? "" : "table-responsive-group"} hidden={hideScheduleRow(scheduleData)}>
                        <th
                          className="table-bgc-light_navy fz-16-md ta-c-md w-140-md fw-b"
                          rowSpan={scheduleData.first.rowSpan < 1 ? 2 : scheduleData.first.rowSpan}
                        >
                          {dateFormat(scheduleData.date,"YYYY年")}
                          <br className="d-n d-b-md" />
                          {i18n.language === "tw" ? <>
                              {dateFormatWithLocale(scheduleData.date, "MM月DD日", i18n.language)}
                              <br className="d-n d-b-md"/>
                              (星期{dateFormatWithLocale(scheduleData.date, "dd", i18n.language)})
                            </>
                          : (dateFormatWithLocale(scheduleData.date, "MM月DD日(ddd)", i18n.language))}
                        </th>
                        {scheduleData.first.type === "flight" ?
                            <></>
                            // <>
                            //   <td className="table-responsive-group-heading table-bgc-light_gray w-140-md ta-c fw-b">
                            //     フライト{scheduleData.first.data.entity.length > 1 && toCircled(1)}
                            //   </td>
                            //   <td>
                            //     <p>
                            //       {/* {scheduleData.first.data.airline + scheduleData.first.data.entity[0].air_sub_no}（{getFlightCodeLabel(scheduleData.first.data.entity[0].depapo)} {getFlightTime(scheduleData.first.data.entity[0].dep)} - {getFlightCodeLabel(scheduleData.first.data.entity[0].arrapo)} {getFlightTime(scheduleData.first.data.entity[0].arv)}） */}
                            //       { getFlightNumberForDisplayExcludeSuffix(scheduleData.first.data) }（{getFlightCodeLabel(scheduleData.first.data.entity[0].depapo)} {getFlightTime(scheduleData.first.data.entity[0].dep)} - {getFlightCodeLabel(scheduleData.first.data.entity[0].arrapo)} {getFlightTime(scheduleData.first.data.entity[0].arv)}）
                            //     </p>
                            //     <p>■{getFlightClassLabel(scheduleData.first.data.entity[0].class_code)}</p>
                            //     <p>■内訳：{getFlightPeopleConfirmLabel(planSelectFormData.searchParams.roomDetail)}<br/>※12歳以上は大人運賃が適用されます。</p>
                            //   </td>
                            // </>
                            : scheduleData.first.type === "activity" ?

                              <TableRowOfPlan
                                heading={t("SectionSchedule.基本プラン")}
                                startDate={dateFormatWithLocale(scheduleData.first.data.date, "YYYY年MM月DD日(ddd)", i18n.language)}
                                startTime={toDisplayTime(scheduleData.first.data.time)}
                                planName={scheduleData.first.data.plan_info.plan_name}
                                addClass="w-140-md"
                              />

                            : scheduleData.first.type === "options" ?

                              <TableRowOfPlan
                                heading={t("SectionDetail.追加オプション")}
                                startDate={dateFormatWithLocale(scheduleData.first.data.date, "YYYY年MM月DD日(ddd)", i18n.language)}
                                startTime={toDisplayTime(scheduleData.first.data.time)}
                                planName={scheduleData.first.data.plan_info.plan_name}
                                addClass="w-140-md"
                              />

                            : scheduleData.first.type === "carRental" && scheduleData.date === scheduleData.first.data.departure_date ?

                              <TableRowOfCarRental
                                headingMode={t("CarRental.貸出")}
                                targetDate={dateFormatWithLocale(scheduleData.first.data.departure_date, "YYYY年MM月DD日(ddd)", i18n.language)}
                                targetTime={scheduleData.first.data.departure_time}
                                office={scheduleData.first.data.departure_office_label}
                                details={scheduleData.first.data.detail}
                                count_option={scheduleData.first.data.count_option}
                                addClass="w-140-md"
                              />

                            : scheduleData.first.type === "carRental" && scheduleData.date === scheduleData.first.data.return_date ?
                           
                              <TableRowOfCarRental
                                headingMode={t("CarRental.返却")}
                                targetDate={dateFormatWithLocale(scheduleData.first.data.return_date, "YYYY年MM月DD日(ddd)", i18n.language)}
                                targetTime={scheduleData.first.data.return_time}
                                office={scheduleData.first.data.return_office_label}
                                details={scheduleData.first.data.detail}
                                count_option={scheduleData.first.data.count_option}
                                addClass="w-140-md"
                              />

                            : scheduleData.first.type === "hotel" ?

                              <TableRowOfHotel
                                hotelName={scheduleData.first.data.detail.hotel_name}
                                roomName={scheduleData.first.data.roomData.room_name}
                                roomText={showRoomText(scheduleData.first.data.plan, t)}
                                planName={scheduleData.first.data.plan.room_plan_detail.plan_name}
                                mealType={showMealLabel(
                                  scheduleData.first.data.plan.room_plan_detail.meal_breakfast,
                                  scheduleData.first.data.plan.room_plan_detail.meal_lunch,
                                  scheduleData.first.data.plan.room_plan_detail.meal_dinner,
                                  t
                                )}
                                addClass="w-140-md"
                              />

                            :<></>
                          }
                      </tr>
                    </>
                    {
                    !emptySchedule(scheduleData) ?
                      <>
                        {scheduleData.rows?.map((row, index) => (
                          <Fragment key={index}>
                            {row.type === "flight" ?
                              <></>
                              // row.data.entity.map((d:any,i:number) => {
                              //   return(
                              //   <Fragment key={i}>
                              //     <tr className="table-responsive-group">
                              //       <td className="table-responsive-group-heading table-bgc-light_gray ta-c fw-b">
                              //         フライト{d?.num && toCircled(Number(d.num))}
                              //       </td>
                              //       <td>
                              //         <p>
                              //           { getFlightNumberForDisplayExcludeSuffix({ airline: row.data.airline, entity: [{ air_code: d.air_code, air_sub_no: d.air_sub_no }]  }) }（{getFlightCodeLabel(d?.depapo)} {getFlightTime(d?.dep)} - {getFlightCodeLabel(d?.arrapo)} {getFlightTime(d?.arv)}）
                              //         </p>
                              //         <p>■{getFlightClassLabel(d?.class_code)}</p>
                              //         <p>■内訳：{getFlightPeopleConfirmLabel(planSelectFormData.searchParams.roomDetail)}<br/>※12歳以上は大人運賃が適用されます。</p>
                              //       </td>
                              //     </tr>
                              //   </Fragment>
                              //   )
                              // })
                            :

                            <tr className="table-responsive-group">
                              {row.type === "activity" ?

                                <TableRowOfPlan
                                  heading={t("SectionSchedule.基本プラン")}
                                  startDate={dateFormatWithLocale(row.data.date, "YYYY年MM月DD日(ddd)", i18n.language)}
                                  startTime={toDisplayTime(row.data.time)}
                                  planName={row.data.plan_info.plan_name}
                                  addClass=""
                                />

                              : row.type === "options" ?

                                <TableRowOfPlan
                                  heading={t("SectionDetail.追加オプション")}
                                  startDate={dateFormatWithLocale(row.data.date, "YYYY年MM月DD日(ddd)", i18n.language)}
                                  startTime={toDisplayTime(row.data.time)}
                                  planName={row.data.plan_info.plan_name}
                                  addClass=""
                                />

                              : row.type === "carRental" && scheduleData.date === row.data.departure_date ?

                                <TableRowOfCarRental
                                  headingMode={t("CarRental.貸出")}
                                  targetDate={dateFormatWithLocale(row.data.departure_date, "YYYY年MM月DD日(ddd)", i18n.language)}
                                  targetTime={row.data.departure_time}
                                  office={row.data.departure_office_label}
                                  details={row.data.detail}
                                  count_option={row.data.count_option}
                                  addClass=""
                                />                              

                              : row.type === "carRental" && scheduleData.date === row.data.return_date ?

                                <TableRowOfCarRental
                                  headingMode={t("CarRental.返却")}
                                  targetDate={dateFormatWithLocale(row.data.return_date, "YYYY年MM月DD日(ddd)", i18n.language)}
                                  targetTime={row.data.return_time}
                                  office={row.data.return_office_label}
                                  details={row.data.detail}
                                  count_option={row.data.count_option}
                                  addClass=""
                                />   

                              : row.type === "hotel" ?

                                <TableRowOfHotel
                                  hotelName={row.data.detail.hotel_name}
                                  roomName={row.data.roomData.room_name}
                                  roomText={showRoomText(row.data.plan, t)}
                                  planName={row.data.plan.room_plan_detail.plan_name}
                                  mealType={showMealLabel(
                                    row.data.plan.room_plan_detail.meal_breakfast,
                                    row.data.plan.room_plan_detail.meal_lunch,
                                    row.data.plan.room_plan_detail.meal_dinner,
                                    t
                                  )}
                                  addClass=""
                                />

                              :<></>
                              }
                            </tr>
                            }
                          </Fragment>
                        ))}
                      </>
                    :                       
                      <tr className={scheduleData.isLast ? "" : "table-responsive-group"} hidden={scheduleData.isLast}>
                        <td className="table-responsive-group-heading table-bgc-light_gray ta-c fw-b" colSpan={2}>
                          {/* 自由行 無行程安排 */}
                          {t("SectionSchedule.お客様自身にて手配")}
                        </td>
                      </tr>
                    }
                  </Fragment>
                ))}
              </>:<></>}
              
            </tbody>
          </table>
        </div>
      </section> 
    </>
  );
};

export default SectionDetail;
