// import { useEffect, useState } from "react";
import { useApi } from "./useApi";
// import {priceResponce} from "../types/price";
import {creditRequestParams, creditResponce} from "../types/credit";
import {PlanSelectFormData} from "../types/planSelectForm";
// import form4Slice, {Form4State} from "../redux/form4Slice";
import {getPriceRequestParams} from "./price";
import {InputsForm4} from "../types/InputsForm4";
import {InputsForm3} from "../types/InputsForm3";
import {InputsForm1} from "../types/InputsForm1";
import {devLog} from "./errors";

/**
 * クレジットカード与信確認
 * 
 * @returns 
 */
export const creditAPI = async (params: creditRequestParams) => {
  try {
    const response = await useApi.post({
      url: "/payment",
      data: params
    });

    const priceData = response.data as creditResponce;

    devLog("priceData", priceData);
    return {status: priceData.status, responce:priceData.data};

  } catch (error) {
    console.error("credit error", error);
  }

  return {status: 500, data: null}
};

/**
 * 与信APIリクエストパラメータ設定
 * @param planSelectFromData
 * @param saveForm1Data
 * @param saveForm3Data
 * @param saveForm4Data
 */
export const getCreditRequestPrams = (planSelectFromData: PlanSelectFormData, saveForm1Data: InputsForm1, saveForm3Data: InputsForm3, saveForm4Data: InputsForm4, languageCode: string): creditRequestParams => {
  const getPaymentParams = getPriceRequestParams(planSelectFromData, saveForm1Data, saveForm3Data, languageCode);
  const payment = {
    "cardNumber": "",
    "cardName": saveForm4Data.payment?.cashcard_name ?? "",
    "cardExpire": "",
    "securityCode": "",
    "jpo": ""
  }
  if (saveForm4Data?.payment?.cashcard_number) {
    payment.cardNumber = saveForm4Data.payment?.cashcard_number.toString() ?? "";
  }
  if (saveForm4Data?.payment?.cashcard_number_of_payments !== null) {
    payment.jpo = saveForm4Data.payment?.cashcard_number_of_payments.toString() ?? "";
  }
  if (saveForm4Data?.payment?.cashcard_cvc !== null) {
    payment.securityCode = saveForm4Data.payment?.cashcard_cvc.toString() ?? "";
  }
  const cashcard_month = saveForm4Data?.payment?.cashcard_month ?? "";
  const cashcard_year = saveForm4Data?.payment?.cashcard_year ?? "";
  payment.cardExpire = cashcard_month + "/" + cashcard_year;
  
  
  return  {
    payment: payment,
    priceParams: getPaymentParams
  }
}