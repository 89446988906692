import React, {useEffect, Fragment, useState} from "react";

import PartsHotelPlanNarrowDown from '../../parts/PartsHotelPlanNarrowDown';

import {PlanSelectFormData} from "../../../types/planSelectForm";
import {hotelList, roomData, roomDataPlanType} from "../../../types/Hotel";
import {
  diffUpdateHotelPrice,
  getHotelAreaCode,
  getRoomTypeLabel,
  hotelRoomSearch,
  isHotelStaySubPrice,
  scrollToModalHotelSection
} from "../../../utils/hotel";
import SearchLoading from "../../loading/searchLoading";
import {SearchNotFound} from "../../index";
import _ from "lodash";
import LoadingOverlay from "react-loading-overlay-ts";
import i18n from "i18next";
import {useTranslation} from "react-i18next";
import {LANGUAGES} from "../../../constants/languages";


// モーダルに何か渡したい場合はここ追加する
type Props = {
  hotelCode: string,
  defaultStep: number,
  roomTypeFilter: {label: string, value: string}[],
  mealFilter: {label: string, value: string}[],
  roomTypeFilterState: string[],
  handleRoomTypeFilter: (
    event: React.MouseEvent<HTMLElement>,
    newRoomDetailToggle: string[],) => void,
  mealFilterState: string[],
  handleMealFilter: (
    event: React.MouseEvent<HTMLElement>,
    newRoomDetailToggle: string[],) => void,
  loading: boolean,
  planSelectFormData: PlanSelectFormData,
  onPageHandle: (step:number) => void,
  selectedRoomSort: string,
  sortRoomPlan: (sortType: string) => void,
  hotelDetail: hotelList | undefined,
  planExists: boolean,
  roomBaxClass: (index:number) => string,
  openRoomDetailModal: (roomData: roomData) => void,
  roomData: roomData | undefined
  onClickPlandetailModal: (planDetail: roomDataPlanType|undefined, roomData: roomData) => void,
  isUpdate: boolean,
  updateHotelIndex: number | undefined,
  checkIn: string,
  checkOut: string,
  callbackUpdateSelectHotel: (planData: roomDataPlanType|undefined, room:roomData) => void,
  StayAccordionStateList: boolean[],
  handleStayAccordionList: (index: number) => void,
  isSelectedRooms: boolean,
  updateHotelDetail: (hotelDetail: hotelList) => void,
  setDefaultHotelDetailData: (hotelDetail: hotelList) => void,
  hotelRoomAllController: AbortController
}

const StaySelectModalStep2: React.FC<Props> = ({
  hotelCode,
  defaultStep,
  roomTypeFilter,
  mealFilter,
  roomTypeFilterState,
  handleRoomTypeFilter,
  mealFilterState,
  handleMealFilter,
  loading,
  planSelectFormData,
  onPageHandle,
  selectedRoomSort,
  sortRoomPlan,
  hotelDetail,
  planExists,
  roomBaxClass,
  openRoomDetailModal,
  roomData,
  onClickPlandetailModal,
  isUpdate,
  updateHotelIndex,
  checkIn,
  checkOut,
  callbackUpdateSelectHotel,
  StayAccordionStateList,
  handleStayAccordionList,
  isSelectedRooms,
  updateHotelDetail,
  setDefaultHotelDetailData,
  hotelRoomAllController
}) => {

  const { t, i18n } = useTranslation();
  const [priceLoading, setPriceLoading] = useState(true);

  useEffect(() => {
    scrollToModalHotelSection(); // モーダルのトップへスクロールする
    if (isSelectedRooms && hotelDetail?.roomData) {
      (async () => {
        // 部屋ごとに処理
        if (hotelDetail.roomData) {
          for (let roomIndex = 0; roomIndex < hotelDetail.roomData?.length; roomIndex++) {
            const room: roomData = hotelDetail.roomData[roomIndex];
            // プランごとに処理
            for (let planIndex = 0; planIndex < room.plan_list.length; planIndex++) {
              const plan = room.plan_list[planIndex];
              // 部屋詳細を取得
              const hotelAreaCode = getHotelAreaCode(planSelectFormData);
              const resultRoom = await hotelRoomSearch(hotelRoomAllController, hotelAreaCode, planSelectFormData.searchParams, hotelCode, room.room_code, plan.plan_code, i18n.language);
              if (resultRoom?.success) {
                // `stay_detail_list`の項目を追加
                const stay_detail_list = resultRoom.response.room_plan_detail.stay_detail_list;
                hotelDetail.roomData[roomIndex].plan_list[planIndex].stay_detail_list = stay_detail_list;
              } else {
                hotelDetail.roomData[roomIndex].plan_list[planIndex].stay_detail_list = [];
              }
              updateHotelDetail(hotelDetail);
              setDefaultHotelDetailData(hotelDetail);
            }
          }
        }
      })();
    }
  }, [isSelectedRooms])

return (<>

  {/* 宿泊プランの絞り込み条件 */}
  {defaultStep === 2 && (
    <PartsHotelPlanNarrowDown
      roomTypeFilter={roomTypeFilter}
      mealFilter={mealFilter}
      roomTypeFilterState={roomTypeFilterState}
      handleRoomTypeFilter={handleRoomTypeFilter}
      mealFilterState={mealFilterState}
      handleMealFilter={handleMealFilter}
    />
  )}

  {/* 宿泊プランの並び替え条件 */}
  <div className={(defaultStep === 2 ? " animation-fade active" : " animation-fade")}>
    <div className="d-f jc-sb ai-c mt-32 mt-40-md mb-16">
      {planSelectFormData.isHotels && (
        <>
          <div className="fz-12 fz-14-md fw-b">
            <button className="button-clear-blue" onClick={()=> onPageHandle(defaultStep-1)}>
              <i className="icon-left-arrow-light_blue mr-5"></i>{t("StaySearchbox.宿泊施設の選択に戻る")}
            </button>
          </div>
          <div className="box-sort small">
            {!loading && (
              <ul className="box-sort-list">
                <li className="box-sort-list-item">
                  <button
                    className={selectedRoomSort !== "asc" ? "box-sort-list-item-link button-clear" : "box-sort-list-item-link button-clear active"}
                    onClick={() => sortRoomPlan("asc")}>{t("StaySearchbox.価格が安い順")}
                  </button>
                </li>
                <li className="box-sort-list-item">
                  <button
                    className={selectedRoomSort !== "desc" ? "box-sort-list-item-link button-clear" : "box-sort-list-item-link button-clear active"}
                    onClick={() => sortRoomPlan("desc")}>{t("StaySearchbox.価格が高い順")}
                  </button>
                </li>
              </ul>
            )}
          </div>
        </>
      )}
    </div>
  </div>

  {/* 表示コンテンツ */}
  {loading ? (
    <SearchLoading isLoading={loading}/>
  ) : (
    <>
      {/* 宿泊プランの検索結果 */}
      <div className={(defaultStep === 2 ? " animation-fade active" : " animation-fade")}>
        <div>
          {hotelDetail !== undefined ? (
            <div>
              <div className="box-heading-navy">{hotelDetail?.hotel_name}</div>
              {planExists === true ? (
                hotelDetail?.roomData?.map((data, rindex) => (
                  data?.plan_list !== undefined && data.plan_list.length > 0 && (
                    <Fragment key={data.room_code}>
                      <div className={roomBaxClass(rindex)}>
                        <div className="box-stay">
                          <div className="box-stay-item">
                            <div className="box-stay-item-thumbnail large">
                              {data?.image_list !== undefined ? (
                                <img src={data?.image_list[0]?.image_url} alt=""/>
                              ) : (
                                <img src={hotelDetail?.image_l_url} alt=""/>
                              )}
                            </div>
                            <div className="box-stay-item-content">
                              <div className="box-stay-item-content-heading">
                                {data?.room_name}
                              </div>
                              <div className="mt-5 mb-16-md">
                                <button
                                  type="button"
                                  onClick={() => openRoomDetailModal(data)}
                                  className="button-border-small-blue w-100per w-a-md"
                                >
                                  客室詳細を見る
                                </button>
                              </div>

                              {/* 以下PCのみ */}
                              <div className="box-stay-item-content-label d-n d-b-md">
                                <div className="list-stay-label">
                                  <ul className="list-stay-label-list">
                                    {data?.room_feature_list?.map((room_feature, rfIndex) => (
                                      <li key={rfIndex} className="list-stay-label-list-item">
                                        {room_feature.name}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                                <p className="fz-13">
                                  【{t("Hotel.ルームタイプ")}】{getRoomTypeLabel(data.room_type_code, i18n.language)}
                                  {data.room_size ? <>【広さ】{data.room_size}㎡</> : ""}
                                  {data.bedding_list && (
                                    <>
                                      【{t("Hotel.ベッドサイズ")}】
                                      {data.bedding_list?.map((bedding) => (
                                        <Fragment key={bedding.name}>
                                          {bedding.name}
                                        </Fragment>
                                      ))}
                                    </>
                                  )}
                                </p>
                              </div>
                            </div>

                            {/* sp時 */}
                            <div className="box-stay-item-content-sp mt-16 mt-0-md mb-16 mb-0-md w-100per">
                              <div className="d-n-md">
                                <div className="list-stay-label">
                                  <ul className="list-stay-label-list mb-20-md">
                                    {data?.room_feature_list?.map((room_feature, rfIndex) => (
                                      <li key={rfIndex} className="list-stay-label-list-item">
                                        {room_feature.name}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                                <p className="fz-13">
                                  【{t("Hotel.ルームタイプ")}】{getRoomTypeLabel(data.room_type_code, i18n.language)}
                                  {data.room_size ? <>【広さ】{data.room_size}㎡</> : ""}
                                  {data.bedding_list && (
                                    <>
                                      【{t("Hotel.ベッドサイズ")}】
                                      {data.bedding_list?.map((bedding)  => (
                                        <Fragment key={bedding.name}>
                                          {bedding.name}
                                        </Fragment>
                                      ))}
                                    </>
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="box-light_navy small radius-bottom">
                        {/* アコーディオンに隠れない一個目の内藤 */}
                        {data.plan_list[0] !== undefined && (
                          <div className="box-stay-list">
                            <div className="box-stay-list-item">
                              <div className="box-white small-pc mb-8 mb-16-md">
                                <div className="box-stay">
                                  <div className="box-stay-item">
                                    <div className="box-stay-item-thumbnail middle">
                                      {data.plan_list[0].plan_image_list !== undefined ? (
                                        <img src={data.plan_list[0].plan_image_list[0].plan_image_url} alt=""/>
                                      ):(
                                        <img src={roomData?.image_list[0].image_url} alt=""/>
                                      )}
                                    </div>
                                    <div className="box-stay-item-content">
                                      <div className="box-stay-item-content-heading">
                                        {data.plan_list[0].plan_name}
                                      </div>
                                      <div className="mt-16 mt-20-md mb-16 mb-20-md">
                                        {data.plan_list[0].stay_detail_list && (
                                          <button
                                            onClick={() => onClickPlandetailModal(data.plan_list[0], data)}
                                            className="button-border-small-blue w-100per w-a-md"
                                          >
                                            {t("HotelPlanSearchResult.プラン詳細を見る")}
                                          </button>
                                        )}
                                      </div>
                                      <div className="box-stay-item-content-label">
                                        <ul className="box-stay-item-content-label-list light-green">
                                          {data.plan_list[0].meal_breakfast === "0" && data.plan_list[0].meal_lunch === "0" && data.plan_list[0].meal_dinner === "0" && (
                                            <li className="box-stay-item-content-label-list-item">
                                              {t("Hotel.食事なし")}
                                            </li>
                                          )}
                                          {data.plan_list[0].meal_breakfast === "1" && (
                                            <li className="box-stay-item-content-label-list-item">
                                              {t("Hotel.朝食")}
                                            </li>
                                          )}
                                          {data.plan_list[0].meal_lunch === "1" && (
                                            <li className="box-stay-item-content-label-list-item">
                                              {t("Hotel.昼食")}
                                            </li>
                                          )}
                                          {data.plan_list[0].meal_dinner === "1" && (
                                            <li className="box-stay-item-content-label-list-item">
                                              {t("Hotel.夕食")}
                                            </li>
                                          )}
                                        </ul>
                                      </div>
                                      <p className="fz-13">
                                        {t("HotelPlanSearchResult.チェックイン")}：{data.plan_list[0].check_in_start}　{t("HotelPlanSearchResult.チェックアウト")}：{data.plan_list[0].check_out}
                                      </p>
                                    </div>
                                    <div className="box-stay-item-detail mt-10 mt-0-md">
                                      <div className="w-100per">
                                        {data.plan_list[0].stay_detail_list && data.plan_list[0].stay_detail_list.length > 0 ? (
                                          <>
                                            {isHotelStaySubPrice(data.plan_list[0].stay_detail_list) ? (<>
                                              <div className="d-f jc-sb d-ib-md w-100per-md mb-10 mb-0-md">
                                                <p className="mb-5-md fz-13 fw-b ta-c-md">
                                                  {t("HotelPlanSearchResult.追加料金")}<br />
                                                  <span className="fz-10 fz-11-md">（{t("HotelPlanSearchResult.１人あたり")}）</span>
                                                </p>
                                                <p className="mb-10-md c-red fz-24 fw-b ta-c-md">
                                                  {diffUpdateHotelPrice(planSelectFormData, {
                                                    isUpdate: isUpdate,
                                                    step: 'plan',
                                                    updateHotelIndex: updateHotelIndex,
                                                    updatePlan: data.plan_list[0],
                                                    updateHotel: undefined,
                                                    checkIn: checkIn,
                                                    checkOut: checkOut
                                                  })}<span className="fz-12">{t("Common.円")}</span>
                                                </p>
                                              </div>
                                              <p className="mb-10-md">
                                                <button
                                                  onClick={() => callbackUpdateSelectHotel(data.plan_list[0], data)}
                                                  className="button-medium-orange button-variable sp-large w-100per"
                                                >
                                                  {
                                                    i18n.language === LANGUAGES.JA ? (
                                                      <>このプランを<br
                                                        className="d-n d-b-md"/>選択する</>
                                                    ) : (
                                                      <>選擇此住宿方案</>
                                                    )
                                                  }
                                                </button>
                                              </p>
                                            </>) : (<>
                                              <div className="box-plan-bt_gray-list-item-content-status">
                                                <div className="d-f d-b-md jc-sb ai-c fx-1">
                                                  <span className="button-medium-disabled w-100per">受付終了</span>
                                                </div>
                                              </div>
                                            </>)}
                                          </>
                                        ) : (
                                          <LoadingOverlay active={true} spinner>
                                            <div className="pb-100"></div>
                                          </LoadingOverlay>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* アコーディオンに隠れるコンテンツ */}
                        {data.plan_list !== undefined && data.plan_list?.length > 1 && (
                          <div>
                            <div className="accordion-stay">
                              <div className={"accordion-stay-detail" + (!StayAccordionStateList[rindex] ? " ":" active")}>
                                <div className="box-stay-list mb-8 mb-16-md">
                                  {data.plan_list?.map((plan_list_data,pIndex) => (
                                    <Fragment key={pIndex}>
                                      {pIndex !== 0 && (
                                        <div className="box-stay-list-item">
                                          <div className="box-white small-pc">
                                            <div className="box-stay">
                                              <div className="box-stay-item">
                                                <div className="box-stay-item-thumbnail sp_small-pc_large">
                                                  {plan_list_data.plan_image_list !== undefined ? (
                                                    <img src={plan_list_data.plan_image_list[0].plan_image_url} alt=""/>
                                                  ) : (
                                                    <img src={roomData?.image_list[0].image_url} alt=""/>
                                                  )}
                                                </div>
                                                <div className="box-stay-item-content">
                                                  <div className="box-stay-item-content-heading">
                                                    {plan_list_data.plan_name}
                                                  </div>
                                                  <div className="mt-16 mt-20-md mb-16 mb-20-md">
                                                    {data.plan_list[0].stay_detail_list && (
                                                      <button
                                                        onClick={() => onClickPlandetailModal(plan_list_data, data)}
                                                        className="button-border-small-blue w-100per w-a-md"
                                                      >
                                                        {t("HotelPlanSearchResult.プラン詳細を見る")}
                                                      </button>
                                                    )}
                                                  </div>
                                                  <div className="box-stay-item-content-label">
                                                    <ul className="box-stay-item-content-label-list light-green">
                                                      {plan_list_data.meal_breakfast === "0" && plan_list_data.meal_lunch === "0" && plan_list_data.meal_dinner === "0" && (
                                                        <li className="box-stay-item-content-label-list-item">
                                                          {t("Hotel.食事なし")}
                                                        </li>
                                                      )}
                                                      {plan_list_data.meal_breakfast === "1" && (
                                                        <li className="box-stay-item-content-label-list-item">
                                                          {t("Hotel.朝食")}
                                                        </li>
                                                      )}
                                                      {plan_list_data.meal_lunch === "1" && (
                                                        <li className="box-stay-item-content-label-list-item">
                                                          {t("Hotel.昼食")}
                                                        </li>
                                                      )}
                                                      {plan_list_data.meal_dinner === "1" && (
                                                        <li className="box-stay-item-content-label-list-item">
                                                          {t("Hotel.夕食")}
                                                        </li>
                                                      )}
                                                    </ul>
                                                  </div>
                                                  <p className="fz-13">
                                                    {t("HotelPlanSearchResult.チェックイン")}：{plan_list_data.check_in_start}　{t("HotelPlanSearchResult.チェックアウト")}：{plan_list_data.check_out}
                                                  </p>
                                                </div>
                                                <div className="box-stay-item-detail mt-10 mt-0-md">
                                                  <div className="w-100per">
                                                    <>
                                                      {plan_list_data?.stay_detail_list ? (
                                                        <>
                                                          {isHotelStaySubPrice(plan_list_data?.stay_detail_list) ? (
                                                            <>
                                                              <div className="d-f jc-sb d-ib-md w-100per-md mb-10 mb-0-md">
                                                                <p className="mb-5-md fz-13 fw-b ta-c-md">
                                                                  {t("HotelPlanSearchResult.追加料金")}<br />
                                                                  <span className="fz-10 fz-11-md">（{t("HotelPlanSearchResult.１人あたり")}）</span>
                                                                </p>
                                                                <p className="mb-10-md c-red fz-24 fw-b ta-c-md">
                                                                  {diffUpdateHotelPrice(planSelectFormData, {
                                                                    isUpdate: isUpdate,
                                                                    step: 'plan',
                                                                    updateHotelIndex: updateHotelIndex,
                                                                    updatePlan: plan_list_data,
                                                                    updateHotel: undefined,
                                                                    checkIn: checkIn,
                                                                    checkOut: checkOut
                                                                  })}<span className="fz-12">{t("Common.円")}</span>
                                                                </p>
                                                              </div>
                                                              <p className="mb-10-md">
                                                                <button
                                                                  onClick={() => callbackUpdateSelectHotel(plan_list_data, data)}
                                                                  className="button-medium-orange button-variable sp-large w-100per"
                                                                >
                                                                  {
                                                                    i18n.language === LANGUAGES.JA ? (
                                                                      <>このプランを<br
                                                                        className="d-n d-b-md"/>選択する</>
                                                                    ) : (
                                                                      <>選擇此住宿方案</>
                                                                    )
                                                                  }
                                                                </button>
                                                              </p>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <div className="box-plan-bt_gray-list-item-content-status">
                                                                <div className="d-f d-b-md jc-sb ai-c fx-1">
                                                                  <span className="button-medium-disabled w-100per">{t("SectionPlan.受付終了")}</span>
                                                                </div>
                                                              </div>
                                                            </>
                                                          )}
                                                        </>
                                                      ) : (
                                                        <LoadingOverlay active={true} spinner>
                                                          <div className="pb-100"></div>
                                                        </LoadingOverlay>
                                                      )}
                                                    </>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Fragment>
                                  ))}
                                </div>
                              </div>

                              {/* アコーディオンの開閉ボタン */}
                              <div className="accordion-stay-heading">
                                <button onClick={() => handleStayAccordionList(rindex)} className="accordion-stay-button button-clear">
                                  {!StayAccordionStateList[rindex] ? (
                                    <span>
                                      <span className="pr-24">{t("HotelPlanSearchResult.宿泊プランをすべて見る")}</span>
                                      <span className="accordion-stay-icon"></span>
                                    </span>
                                  ) : (
                                    <span>
                                      <span className="pr-24">{t("HotelPlanSearchResult.閉じる")}</span>
                                      <span className="accordion-stay-icon active"></span>
                                    </span>
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Fragment>
                  )
                ))
              ) : (
                <>
                  {/* プランが存在しない場合 */}
                  <div className="box-white radius-none small-pc">
                    <div className="box-stay">
                      <div className="box-stay-item">
                        <div className="box-stay-item-content">
                          <section className="pt-20 pb-20">
                            <div className="wrap-content-900">
                              <p className="mb-10 ta-c">
                                <img src={require("../../../img/share/icon/icon_searchzero.svg").default} alt="" className="d-ib" />
                              </p>
                              <p className="fz-18 fw-b ta-c mb-16 c-dark_blue2">{t("HotelPlanSearchResult.選択可能なプランが存在しません")}</p>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <SearchNotFound></SearchNotFound>
          )}
        </div>

        {planSelectFormData.isHotels && !priceLoading && (
          <div className="mt-32 ta-c">
            <button onClick={()=> onPageHandle(defaultStep-1)} className="button-border-small-blue medium w-100per w-a-md ml-a-md mr-a-md">
              <i className="icon-left-arrow-light_blue mr-5"></i>{t("StaySearchbox.宿泊施設の選択に戻る")}
            </button>
          </div>
        )}
      </div>
    </>
  )}
</>);
};

export default StaySelectModalStep2;