import React, {useEffect} from "react";

// // toggle button
// import ToggleButton from '@material-ui/lab/ToggleButton';
// import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';


//モーダルインポート
import HotelHandModal from "../modal/HotelHandModal";
import {Hotel, HotelAllSearchResponse, hotelList} from "../../types/Hotel";

import LoadingOverlay from 'react-loading-overlay-ts';
import {
  diffHotelPrice,
  getHotelTypeLabel, isOneHotel, scrollToHotelSection,
  // hotelTagImageName
} from "../../utils/hotel";
import {defaultHotelDetail} from "../../defaults/hotel";
import {
  PlanSelectFormData, PlanSelectFromHotelType,
  // PlanSelectFromHotelType
} from "../../types/planSelectForm";
// import {PlanSearch} from "../../types/Search";
import PartsHotelTagList from "../parts/PartsHotelTagList";
import SearchNotFound from "../SearchNotFound";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../../constants/languages";
import {CircularProgress} from "@material-ui/core";

type Props = {
  staySearchPageView: number
  setStaySearchPageView: ( index: number ) => void,
  // hotel: Hotel | undefined,
  planSelectFormData: PlanSelectFormData | undefined,
  isLoading: boolean,
  isListLoading: boolean,
  selectHotel: (hotelCode:string) => void,
  setRoomTypeFilterState: any,
  setMealFilterState: any,
  setRoomTypeFilter: any,
  setMealFilter: any,
  hotelAllData: HotelAllSearchResponse | undefined;
}

/**
 * ホテル一覧 表示
 * @param staySearchPageView
 * @param setStaySearchPageView
 * @param hotel
 * @param planSelectFormData
 * @param isLoading
 * @param selectHotel
 * @constructor
 */
const StaySearchResult: React.VFC<Props> = ({
  staySearchPageView,
  setStaySearchPageView,
  // hotel,
  planSelectFormData,
  isLoading,
  isListLoading,
  selectHotel,
  setRoomTypeFilterState,
  setMealFilterState,
  setRoomTypeFilter,
  setMealFilter,
  hotelAllData
}) => {

    const { t, i18n } = useTranslation();

  const checkIn = planSelectFormData?.searchParams.checkin ?? '';
  const checkOut = planSelectFormData?.searchParams.checkout ?? '';
  //モーダル：ホテル詳細
  const [HotelHandModalState, setHotelHandModalState] = React.useState(false);
  const handlHotelHandModal = () => {
    setHotelHandModalState(!HotelHandModalState);
  };
  const [hotelDetail, setHotelDetail] = React.useState<hotelList>(defaultHotelDetail);
  const [reserveHotel, setReserveHotel] = React.useState<PlanSelectFromHotelType>();
  /**
   * ホテルの詳細モーダルを表示
   * @param hotelDetailData
   */
  const handlHotelDetailModal  = (hotelDetailData: hotelList) => {
    setHotelDetail(hotelDetailData);
    setHotelHandModalState(!HotelHandModalState);
  }

  useEffect(() => {
    if (planSelectFormData !== undefined && planSelectFormData?.hotel !== undefined && planSelectFormData?.hotel.length > 0) {
      setReserveHotel(planSelectFormData.hotel[0]);
    }
  }, [planSelectFormData])

  useEffect(() => {
    // 事業者が`ホテル指定`でホテルを1施設のみ設定した場合、部屋詳細を表示する
    if (hotelAllData && planSelectFormData?.hotelData?.length === 1) {
      if (isOneHotel(planSelectFormData)) {
        setRoomTypeFilterState([]); // 部屋タイプ
        setMealFilterState([]); // 食事
        selectHotel(hotelAllData.hotelList[0].hotel_code);
        setStaySearchPageView(staySearchPageView + 1)
      }
    }
  }, [hotelAllData]);

  /**
   * ホテルを選択
   * @param hotelCode
   */
  const handleSelectHotel = (hotelCode: string) => {
    // 選択済みの絞り込み条件を選択解除する
    setRoomTypeFilterState([]); // 部屋タイプ
    setMealFilterState([]); // 食事
    selectHotel(hotelCode);
    setStaySearchPageView(staySearchPageView + 1)
    scrollToHotelSection(); // `宿泊の選択`の場所にスクロールする
  }

  const handleSelectHotelDetail = (hotelCode: string) => {
    selectHotel(hotelCode);
    setHotelHandModalState(!HotelHandModalState);
    setStaySearchPageView(staySearchPageView + 1)
    scrollToHotelSection(); // `宿泊の選択`の場所にスクロールする
  }

    return (
        <>
          {(hotelAllData?.hotelList && hotelAllData.hotelList?.length > 0 || isLoading) ? (
            <div className={"animation-fade" + (staySearchPageView === 1 && " active")}>
              <div className="box-plan-bt_gray">
                <div className="box-plan-bt_gray-list">
                  {isLoading ? (
                    <>
                      {/* ローディング表示 */}
                      <LoadingOverlay active={isLoading} spinner>
                        <div className="pb-100"></div>
                      </LoadingOverlay>
                    </>
                  ) : (
                    <>
                      {(hotelAllData?.hotelList && hotelAllData.hotelList?.length > 0)  && (
                        <>
                          {/* ホテル一覧を表示 */}
                          {hotelAllData.hotelList.map((hotelData) => (
                            <div key={"hotel_code_" + hotelData.hotel_code} className="box-plan-bt_gray-list-item">
                              <div className="box-stay">
                                <div className="box-stay-item">
                                  <div className="box-stay-item-thumbnail large">
                                    <img src={hotelData.image_m_url} alt={hotelData.hotel_name}/>
                                  </div>
                                  <div className="box-stay-item-content">
                                    <div className="box-stay-item-content-area">
                                      <div>
                                        {/* サブエリア(市町村) */}
                                        {hotelData.sub_area_name !== undefined && (
                                          <ul className="list-stay-area-list">
                                            <li className="list-stay-area-list-item">{hotelData.sub_area_name}</li>
                                          </ul>
                                        )}
                                      </div>
                                    </div>
                                    <div className="box-stay-item-content-heading mb-medium">
                                      {hotelData.hotel_name}
                                    </div>
                                    <div className="mb-16-md">
                                      <button
                                        onClick={() => handlHotelDetailModal(hotelData)}
                                        className="button-border-small-blue w-100per w-a-md"
                                      >
                                        {t("StaySearchResult.ホテルの詳細を見る")}
                                      </button>
                                    </div>

                                    {/* 以下PCのみ */}
                                    <div className="box-stay-item-content-label d-n d-b-md">
                                      <div className="list-stay-label mb-small">
                                        <ul className="list-stay-label-list mb-0-md">
                                          {hotelData.hotel_type_list?.map((hotel_type) => (
                                            <li key={hotel_type.hotel_type_code} className="list-stay-label-list-item">
                                              {getHotelTypeLabel(hotel_type.hotel_type_code, i18n.language)}
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                      {/* 一旦APIで出力できないため非表示 */}
                                      <div className="d-n d-b-md">
                                        <PartsHotelTagList
                                          hotel_facility_list={hotelData.hotel_facility_list}
                                          ulClass="parts-hotel_tag-list d-f fw-w"
                                          languageCode={i18n.language}
                                        ></PartsHotelTagList>
                                      </div>
                                    </div>
                                  </div>

                                  {/* sp時 */}
                                  <div className="box-stay-item-content-sp mt-20 mb-16">
                                    <div className="d-n-md">
                                      <div className="list-stay-label mb-small">
                                        <ul className="list-stay-label-list mb-20-md">
                                          {hotelData.hotel_type_list?.map((hotel_type) => (
                                            <li key={hotel_type.hotel_type_code} className="list-stay-label-list-item">
                                              {getHotelTypeLabel(hotel_type.hotel_type_code, i18n.language)}
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                      <div className="mt-4">
                                        <PartsHotelTagList
                                          hotel_facility_list={hotelData.hotel_facility_list}
                                          ulClass="parts-hotel_tag-list d-f fw-w"
                                          languageCode={i18n.language}
                                        ></PartsHotelTagList>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="box-stay-item-detail">
                                    <div className="w-100per">
                                      {hotelData.sold_out_flg === 1 ? (<>
                                        <div className="d-f jc-sb d-ib-md w-100per-md mb-0-md">
                                          <div className="d-f d-b-md jc-sb ai-c fx-1">
                                            <span
                                              className="button-medium-disabled w-100per">{t("SectionPlan.受付終了")}</span>
                                          </div>
                                        </div>
                                      </>) : (<>
                                        <div className="d-f jc-sb d-ib-md w-100per-md mb-10 mb-0-md">
                                          <p className="mb-5-md fz-13 fw-b ta-c-md">
                                            {t("HotelPlanSearchResult.追加料金")}<br/>
                                            <span
                                              className="fz-10 fz-11-md">（{t("HotelPlanSearchResult.１人あたり")}）</span>
                                          </p>
                                          <p className="mb-10-md c-red fz-24 fw-b ta-c-md">
                                            {diffHotelPrice(planSelectFormData, {
                                              isUpdate: false,
                                              step: 'hotel',
                                              updateHotelIndex: 0,
                                              updatePlan: undefined,
                                              updateHotel: hotelData,
                                              checkIn: checkIn,
                                              checkOut: checkOut
                                            })}<span className="fz-12">{t("Common.円")}〜</span>
                                          </p>
                                        </div>
                                        <p className="mb-10-md">
                                          <button
                                            onClick={() => handleSelectHotel(hotelData.hotel_code)}
                                            className="button-medium-orange button-variable sp-large w-100per"
                                          >
                                            {
                                              i18n.language === LANGUAGES.JA ? (
                                                <>この宿泊施設を<br className="d-n d-b-md"/>選択する</>
                                              ) : (
                                                <>選擇此住宿設施</>
                                              )
                                            }
                                          </button>
                                        </p>
                                      </>)}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                          {/* モーダル：ホテル詳細 */}
                          <HotelHandModal
                            selectHotel={handleSelectHotelDetail}
                            isUpdate={true}
                            updateIndex={0}
                            planSelectFormData={planSelectFormData}
                            isShow={HotelHandModalState}
                            hotelList={hotelDetail}
                            callback={handlHotelHandModal}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>) : (
            <>
              {!isListLoading && (
                <SearchNotFound></SearchNotFound>
              )}
            </>
          )}
          {(!isLoading && isListLoading) && (
            <>
              {hotelAllData && hotelAllData.hotelList && hotelAllData.hotelList.length > 0 ? (
                <div className={"p-30 ta-c"}>
                  <CircularProgress color="inherit" size={28} />
                </div>
              ) : (
                <LoadingOverlay active={true} spinner>
                  <div className="pb-100"></div>
                </LoadingOverlay>
              )}
            </>
          )}
          
        </>
    );
}
export default StaySearchResult;